import { defineMessages } from 'react-intl'

export default defineMessages({
  assignToUser: {
    id: 'app.containers.RoleManagementPage.assignToUser',
    defaultMessage: 'Assign to User',
  },
  assignRoleToUser: {
    id: 'app.containers.RoleManagementPage.assignRoleToUser',
    defaultMessage: 'Assign Role to User',
  },
  assignmentFormError: {
    id: 'app.containers.RoleManagementPage.assignmentFormError',
    defaultMessage: 'Please enter all required fields',
  },
  assignmentFormSubmitError: {
    id: 'app.containers.RoleManagementPage.assignmentFormError',
    defaultMessage:
      'There was an error creating the assignment. You may not have permission to assign this role.',
  },
  cancel: {
    id: 'app.containers.RoleManagementPage.cancel',
    defaultMessage: 'Cancel',
  },
  createRole: {
    id: 'app.containers.RoleManagementPage.createRole',
    defaultMessage: 'Create Role',
  },
  description: {
    id: 'app.containers.RoleManagementPage.description',
    defaultMessage: 'Description',
  },
  errorOccurred: {
    id: 'app.containers.RoleManagementPage.errorOccurred',
    defaultMessage: 'An error occurred',
  },
  forSwimlanePermissions: {
    id: 'app.containers.RoleManagementPage.forSwimlanePermissions',
    defaultMessage: 'for swimlane permissions only',
  },
  name: {
    id: 'app.containers.RoleManagementPage.name',
    defaultMessage: 'Name',
  },
  numberOfUsers: {
    id: 'app.containers.RoleManagementPage.numberOfUsers',
    defaultMessage: 'Number Of Users',
  },
  permissions: {
    id: 'app.containers.RoleManagementPage.permissions',
    defaultMessage: 'Permissions',
  },
  platformPerms: {
    id: 'app.containers.RoleManagementPage.platformPerms',
    defaultMessage: 'Allow Platform Permissions',
  },
  roleDetails: {
    id: 'app.containers.RoleManagementPage.roleDetails',
    defaultMessage: 'Role Details',
  },
  roles: {
    id: 'app.containers.RoleManagementPage.roles',
    defaultMessage: 'Roles',
  },
  save: {
    id: 'app.containers.RoleManagementPage.save',
    defaultMessage: 'Save',
  },
  selectAllSwimlanes: {
    id: 'app.containers.RoleManagementPage.selectAllSwimlanes',
    defaultMessage: 'All',
  },
  selectAllSwimlanesMessage: {
    id: 'app.containers.RoleManagementPage.selectAllSwimlanesMessage',
    defaultMessage:
      'Assign this role for all swimlanes, including swimlanes created in the future',
  },
  scope: {
    id: 'app.containers.RoleManagementPage.scope',
    defaultMessage: 'Scope',
  },
  selectAll: {
    id: 'app.containers.RoleManagementPage.selectAll',
    defaultMessage: 'Select All',
  },
  unassign: {
    id: 'app.containers.RoleManagementPage.unassign',
    defaultMessage: 'Unassign',
  },
  unassignRole: {
    id: 'app.containers.RoleManagementPage.unassignRole',
    defaultMessage: 'Unassign Role',
  },
  unselectAll: {
    id: 'app.containers.RoleManagementPage.unselectAll',
    defaultMessage: 'Unselect All',
  },
  user: {
    id: 'app.containers.RoleManagementPage.user',
    defaultMessage: 'User',
  },
  users: {
    id: 'app.containers.RoleManagementPage.users',
    defaultMessage: 'Users',
  },
  usersAssignmentPreamble: {
    id: 'app.containers.RoleManagementPage.users',
    defaultMessage: 'The following users have been assigned this role',
  },

  // ~~ permission type names ~~
  permissionTypeName_sonrai: {
    id: 'app.containers.RoleManagementPage.permissionTypeName_sonrai',
    defaultMessage: 'Sonrai Platform',
  },
  permissionTypeName_swimlane: {
    id: 'app.containers.RoleManagementPage.permissionTypeName_swimlane',
    defaultMessage: 'Swimlane',
  },

  // ~~~ permission type descriptions ~~~
  permissionTypeDescription_sonrai: {
    id: 'app.containers.RoleManagementPage.permissionTypeName_sonrai',
    defaultMessage: 'Allowed Sonrai platform permissions',
  },
  permissionTypeDescription_swimlane: {
    id: 'app.containers.RoleManagementPage.permissionTypeName_swimlane',
    defaultMessage: 'Allowed swimlane permissions',
  },

  // ~~~ permission actions ~~~
  ['permissionAction_*']: {
    id: 'app.containers.RoleManagementPage.permissionAction_*',
    defaultMessage: '*',
  },
  ['permissionAction_edit.exemptedidentity']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.exemptedidentity',
    defaultMessage: 'Edit Exempted Identity',
  },
  ['permissionAction_edit.integrations']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.integrations',
    defaultMessage: 'Edit Integration',
  },
  ['permissionAction_assign.integrations']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_assign.integrations',
    defaultMessage: 'Assign Integration',
  },
  ['permissionAction_edit.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.tickets',
    defaultMessage: 'Edit Ticket',
  },
  ['permissionAction_edit.objectives']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.objectives',
    defaultMessage: 'Edit Objectives',
  },
  ['permissionAction_edit.tickettemplates']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.tickettemplates',
    defaultMessage: 'Edit Custom Ticket Templates',
  },
  ['permissionAction_acceptrisk.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionAction_acceptrisk.tickets',
    defaultMessage: 'Accept Ticket Risk',
  },
  ['permissionAction_snooze.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionAction_snooze.tickets',
    defaultMessage: 'Snooze Tickets',
  },
  ['permissionAction_transition.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionAction_transition.tickets',
    defaultMessage: 'Transition Ticket',
  },
  ['permissionAction_edit.swimlanes']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.swimlanes',
    defaultMessage: 'Edit Swimlane',
  },
  ['permissionAction_view.swimlane']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.swimlane',
    defaultMessage: 'View Swimlane',
  },
  ['permissionAction_view.data']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.data',
    defaultMessage: 'View Data',
  },
  ['permissionAction_edit.controlframeworks']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.controlframeworks',
    defaultMessage: 'Edit Control Frameworks',
  },
  ['permissionAction_view.controlframeworks']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_view.controlframeworks',
    defaultMessage: 'View Control Frameworks',
  },
  ['permissionAction_edit.alerts']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.alerts',
    defaultMessage: 'Edit Alerts',
  },
  ['permissionAction_assign.bots']: {
    id: 'app.containers.RoleManagementPage.permissionAction_assign.bots',
    defaultMessage: 'Assign Bots',
  },
  ['permissionAction_edit.bots']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.bots',
    defaultMessage: 'Edit Bots',
  },
  ['permissionAction_edit.collectors']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.collectors',
    defaultMessage: 'Edit Collectors',
  },
  ['permissionAction_edit.crmsettings']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.crmsettings',
    defaultMessage: 'Edit CRM Settings',
  },
  ['permissionAction_edit.dataclassificationconfig']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.edit.dataclassificationconfig',
    defaultMessage: 'Edit Data Classification Configuration',
  },
  ['permissionAction_edit.roles']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.roles',
    defaultMessage: 'Edit Roles',
  },
  ['permissionAction_edit.searches']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.searches',
    defaultMessage: 'Edit Searches',
  },
  ['permissionAction_edit.resources']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.resources',
    defaultMessage: 'Edit Resources',
  },
  ['permissionAction_edit.solutioncards']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.solutioncards',
    defaultMessage: 'Edit Solution Cards',
  },
  ['permissionAction_execute.bots']: {
    id: 'app.containers.RoleManagementPage.permissionAction_execute.bots',
    defaultMessage: 'Execute Bots',
  },
  ['permissionAction_view.ticket']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.ticket',
    defaultMessage: 'View Tickets',
  },
  ['permissionAction_edit.ticket']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.ticket',
    defaultMessage: 'Edit Tickets',
  },
  ['permissionAction_edit.users']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.users',
    defaultMessage: 'Edit Users',
  },
  ['permissionAction_edit.invites']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.invites',
    defaultMessage: 'Edit Invites',
  },
  ['permissionAction_view.audit']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.audit',
    defaultMessage: 'View Audit',
  },
  ['permissionAction_view.collectors']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.ticket',
    defaultMessage: 'View Collectors',
  },
  ['permissionAction_edit.roleassignments']: {
    id: 'app.containers.RoleManagementPage.edit.roleassignments',
    defaultMessage: 'Edit Role Assignments',
  },
  ['permissionAction_edit.escalations']: {
    id: 'app.containers.RoleManagementPage.edit.escalations',
    defaultMessage: 'Edit Escalation Schemes',
  },
  ['permissionAction_assign.escalations']: {
    id: 'app.containers.RoleManagementPage.assign.escalations',
    defaultMessage: 'Assign Escalations',
  },
  ['permissionAction_edit.customclassifiers']: {
    id: 'app.containers.RoleManagementPage.edit.customclassifiers',
    defaultMessage: 'Edit Custom Data Classifiers',
  },
  // ~~ permission descriptions ~~
  ['permissionDescription_*']: {
    id: 'app.containers.RoleManagementPage.permissionAction_*',
    defaultMessage: 'Super privilege can do anything',
  },
  ['permissionDescription_edit.alerts']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.alerts',
    defaultMessage: 'Dismiss alerts but not change the baseline',
  },
  ['permissionDescription_edit.exemptedidentity']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.exemptedidentity',
    defaultMessage:
      'Create and edit a list of resource IDs that are exempt from Sonrai prevention policies',
  },
  ['permissionDescription_edit.integrations']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.integrations',
    defaultMessage: 'Create, update and remove platform integrations',
  },
  ['permissionDescription_assign.integrations']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_assign.integrations',
    defaultMessage:
      'Enable integration notifications from tickets in the assigned swimlane',
  },
  ['permissionDescription_edit.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.tickets',
    defaultMessage: 'Modify tickets in this swimlane',
  },
  ['permissionDescription_edit.objectives']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.objectives',
    defaultMessage: 'Create, Update, Delete, and Reorder Objectives',
  },
  ['permissionDescription_edit.tickettemplates']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.tickettemplates',
    defaultMessage: 'Modify templates for custom tickets',
  },
  ['permissionDescription_transition.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.tickets',
    defaultMessage: 'Update the status of tickets in this swimlane',
  },
  ['permissionDescription_acceptrisk.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.tickets',
    defaultMessage: 'Accept the risk identified in a ticket',
  },
  ['permissionDescription_snooze.tickets']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.tickets',
    defaultMessage: 'Snooze a ticket',
  },

  ['permissionDescription_edit.crmsettings']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.crmsettings',
    defaultMessage:
      'Set baseline, enable or disable monitoring and change detection and also they can dismiss alerts',
  },
  ['permissionDescription_edit.dataclassificationconfig']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.edit.dataclassificationconfig',
    defaultMessage:
      'Submit jobs to classify data contained in buckets, blob storage and databases',
  },
  ['permissionDescription_edit.resources']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.resources',
    defaultMessage: 'Change importance, set friendly name, add/remove tags',
  },
  ['permissionDescription_edit.roles']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.roles',
    defaultMessage:
      'Create, update and delete custom roles and their permissions',
  },
  ['permissionDescription_execute.bots']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_execute.bots',
    defaultMessage:
      'Manually trigger remediation bots to run for a ticket in their swimlane',
  },
  ['permissionDescription_edit.searches']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_edit.alerts',
    defaultMessage: 'Create, Update, and Delete custom searches',
  },
  ['permissionDescription_edit.solutioncards']: {
    id:
      'app.containers.RoleManagementPage.permissionDescription_edit.solutioncards',
    defaultMessage: 'Create, Update, and Delete Solution Cards and Widgets',
  },
  ['permissionDescription_edit.swimlanes']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.swimlanes',
    defaultMessage:
      'Users will be allowed to edit the configuration of swimanes',
  },
  ['permissionDescription_edit.users']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.users',
    defaultMessage:
      'Invite, disable, and re-enable users, and also can reassign users to different roles',
  },
  ['permissionDescription_edit.invites']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.invites',
    defaultMessage: 'Create, Update, and Delete, user invitations.',
  },
  ['permissionDescription_view.swimlane']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.swimlane',
    defaultMessage:
      'Users will be allowed to view the configuration of the swimane',
  },
  ['permissionDescription_view.data']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.data',
    defaultMessage:
      'Users will see data from this swimlane in search results and solution center widgets and will be able to view the node views for any resources in the swimlane.',
  },
  ['permissionDescription_edit.controlframeworks']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.controlframeworks',
    defaultMessage: 'Users will be able to edit the control framework',
  },
  ['permissionDescription_view.controlframeworks']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_view.controlframeworks',
    defaultMessage: 'Users will be able to view the control framework',
  },
  ['permissionDescription_edit.bots']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.bots',
    defaultMessage: 'Users can edit and delete bots',
  },
  ['permissionDescription_assign.bots']: {
    id: 'app.containers.RoleManagementPage.permissionDescription_assign.bots',
    defaultMessage: 'Users can assign bots to swimlanes',
  },
  ['permissionDescription_view.ticket']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.ticket',
    defaultMessage:
      'Users will be able to to view the tickets assigned to them and others',
  },
  ['permissionDescription_edit.ticket']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.ticket',
    defaultMessage: 'Users can edit tickets in the system',
  },
  ['permissionDescription_view.collectors']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.ticket',
    defaultMessage: 'Users will be able to to view the collectors',
  },
  ['permissionDescription_edit.collectors']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.ticket',
    defaultMessage: 'Users can edit collectors',
  },
  ['permissionDescription_view.audit']: {
    id: 'app.containers.RoleManagementPage.permissionAction_view.audit',
    defaultMessage: 'View audit logs of user activity',
  },
  ['permissionDescription_edit.roleassignments']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.roleassignments',
    defaultMessage:
      "Add and remove other users's roles. Cannot add roles outside of their own scope.",
  },
  ['permissionDescription_edit.escalations']: {
    id: 'app.containers.RoleManagementPage.permissionAction_edit.escalations',
    defaultMessage:
      'Create and delete escalation schemes while also creating and deleting escalation assignments, filters, and rules.',
  },
  ['permissionDescription_assign.escalations']: {
    id: 'app.containers.RoleManagementPage.permissionAction_assign.escalations',
    defaultMessage:
      'Assign and unassign swimlanes to escalation schemes. Cannot assign or unassign swimlanes they do not have ownership of.',
  },
  ['permissionDescription_edit.customclassifiers']: {
    id:
      'app.containers.RoleManagementPage.permissionAction_edit.customclassifiers',
    defaultMessage: 'Create, Update, and Delete Custom Data Classifiers.',
  },
})
