const PREFIX = 'app/containers/RoleDetails/'

export const EDIT_FORM_VALUE_CHANGE = PREFIX + 'EDIT_FORM_VALUE_CHANGE'

export const ASSIGN_USER_TO_ROLE = PREFIX + 'ASSIGN_USER_TO_ROLE'
export const ASSIGN_USER_TO_ROLE_SUCCESS =
  PREFIX + 'ASSIGN_USER_TO_ROLE_SUCCESS'
export const ASSIGN_USER_TO_ROLE_ERROR = PREFIX + 'ASSIGN_USER_TO_ROLE_ERROR'
export const ASSIGN_USER_TO_ROLE_ERROR_CLEAR =
  PREFIX + 'ASSIGN_USER_TO_ROLE_ERROR_CLEAR'

export const UNASSIGN_USERS_FROM_ROLE = PREFIX + 'UNASSIGN_USERS_FROM_ROLE'
export const UNASSIGN_USERS_FROM_ROLE_SUCCESS =
  PREFIX + 'UNASSIGN_USERS_FROM_ROLE_SUCCESS'
export const UNASSIGN_USERS_FROM_ROLE_ERROR =
  PREFIX + 'UNASSIGN_USERS_FROM_ROLE_ERROR'
