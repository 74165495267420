import { createSelector } from 'reselect'
import qs from 'query-string'
import _ from 'lodash'
import { Map, List } from 'immutable'

import { selectAllRoles } from 'containers/SonraiData/selectors'
import { initialState } from './reducers'

const selectRoleDetailsDomain = state =>
  state.get('roleDetails') || initialState

export const selectEdittingRole = createSelector(
  selectRoleDetailsDomain,
  state => state.get('edittingRole')
)

export const selectRoleAssignment = createSelector(
  selectRoleDetailsDomain,
  state => state.get('roleAssignment')
)

export const selectRoleUnassignment = createSelector(
  selectRoleDetailsDomain,
  state => state.get('roleUnassignment')
)

export const selectRoleId = (state, props) => {
  return qs.parse(_.get(props, ['location', 'search'], '')).roleId
}

export const selectRole = createSelector(
  [selectAllRoles, selectRoleId],
  (sonraiRoles, roleId) =>
    sonraiRoles.get('data', List()).find(role => role.get('srn') === roleId) ||
    Map()
)
