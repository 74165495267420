import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { fromJS, List, Set, Map } from 'immutable'
import _ from 'lodash'
import {
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import Select from 'react-select'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import ScopeAssignmentForm from 'components/ScopeAssignmentForm'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import { getCurrentOrg } from 'auth/current-org'

import { SWIMLANE_SCOPED_PERMISSIONS } from 'appConstants'
import messages from './messages'

const initialState = () => ({
  assignmentModalOpen: false,
  assignmentFormError: false,
  assignmentFormValue: fromJS({
    userSrn: null,
    scopes: null,
    platform: false,
  }),
})

/**
 * This component is the modal that comes up when on the role managment
 * page that can be used to assign a role to a user. It also renders the
 * button
 */
class AssignRoleToUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = initialState()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.roleAssignment.get('inProgress') &&
      !this.props.roleAssignment.get('inProgress') &&
      !this.props.roleAssignment.get('error')
    ) {
      this.closeAssignmentModal()
    }
  }

  openAssignmentModal = () => {
    this.setState({ assignmentModalOpen: true })
  }

  closeAssignmentModal = () => {
    this.setState(initialState())
    this.props.assignUserToRoleErrorClear()
  }

  handleUserAssignSelect = value => {
    this.setState(oldState => ({
      assignmentFormValue: oldState.assignmentFormValue
        .set('userSrn', value)
        .set('scopes', null)
        .set('platform', false),
      assignmentFormError: false,
    }))
  }

  handleScopeAssignSelect = value => {
    this.setState(oldState => ({
      assignmentFormValue: oldState.assignmentFormValue.set('scopes', value),
      assignmentFormError: false,
    }))
  }

  handleAssignForPlatform = () => {
    this.setState(oldState => ({
      assignmentFormValue: oldState.assignmentFormValue
        .set('platform', !oldState.assignmentFormValue.get('platform'))
        .set('scopes', null),
      assignmentFormError: false,
    }))
  }

  handleAssignRoleToUser = () => {
    const { userSrn, scopes, platform } = this.state.assignmentFormValue.toJS()
    const hasSwimlanePerms = this.hasSwimlaneScopedPermissions()
    const userOrg = getCurrentOrg()
    const specialOrgScope = `/org/${userOrg}/*`

    if (!userSrn || (hasSwimlanePerms && !scopes && !platform)) {
      this.setState({ assignmentFormError: true })
    } else {
      const scopeSRNs = (scopes || [])
        .filter(({ value }) => value !== 'All')
        .map(({ value }) => value)
      if (platform || _.isEmpty(scopeSRNs)) {
        scopeSRNs.push(specialOrgScope)
      }

      this.props.assignUserToRole({
        userSrn: userSrn.value,
        scopes: scopeSRNs,
        roleSrn: this.props.roleDetails.get('srn'),
      })
    }
  }

  /**
   * This will make a map like:
   * {
   *   <user_idA>: {
   *     data: {<user data>},
   *     scopes: [ <scopeA>, <scopeB> ]
   *   },
   *   <user_idB>: {
   *     data: {...},
   *     scopes: [ <scopeB>, <scopeC> ]
   *   },
   * }
   *
   * that we can use to figure out if the user already has the role assigned.
   * We want this datastructure so we can set the options in the modal dropdown
   *
   * Using immutable so can use the Set() class for the scopes b/c the
   * includes() method has better performance
   */
  constructScopeAssignmentIndex = () => {
    const scopeAssignmentIndex = {}
    const roleAssignments = this.props.roleDetails.getIn([
      'roleAssignments',
      'items',
    ])

    roleAssignments.forEach(assignment => {
      const user = assignment.getIn(['user', 'items', 0])
      const scope = assignment.get('scope')
      const userId = user.get('srn')

      // if user not aleady in structure, add empty set for them
      if (!scopeAssignmentIndex[userId]) {
        scopeAssignmentIndex[userId] = {}
        scopeAssignmentIndex[userId].data = user
        scopeAssignmentIndex[userId].scopes = new Set()
      }

      // if the user's entry in our map doesn't contain the scope, add to set
      if (!scopeAssignmentIndex[userId].scopes.includes[scope]) {
        scopeAssignmentIndex[userId].scopes = scopeAssignmentIndex[
          userId
        ].scopes.add(scope)
      }
    })

    return fromJS(scopeAssignmentIndex)
  }

  hasSwimlaneScopedPermissions = () => {
    const permissions = this.props.roleDetails.get('permissions', List()).toJS()
    for (let permission of permissions) {
      if (SWIMLANE_SCOPED_PERMISSIONS.includes(permission)) {
        return true
      }
    }
  }

  hasNonSwimlaneScopedPermissions = () => {
    const permissions = this.props.roleDetails.get('permissions', List()).toJS()
    for (let permission of permissions) {
      if (!SWIMLANE_SCOPED_PERMISSIONS.includes(permission)) {
        return true
      }
    }
  }

  renderModalBody() {
    if (this.props.usersloading) {
      return <SquareLoadingAnimation />
    }

    const scopeAssignmentIndex = this.constructScopeAssignmentIndex()
    const hasSwimlanePerms = this.hasSwimlaneScopedPermissions()
    const hasNonSwimlanePerms = this.hasNonSwimlaneScopedPermissions()
    const swimlaneResourceIds = new Set(
      Object.values(this.props.swimlanes.toJS()).map(
        ({ resourceId }) => resourceId
      )
    )
    const userOrg = getCurrentOrg()
    const specialOrgScope = `/org/${userOrg}/*`

    let userOptions = []
    this.props.allUsers
      .filter(user => {
        const userId = user.get('srn')
        const userAssignments = scopeAssignmentIndex.get(userId)
        if (!userAssignments) {
          return true
        }

        let userAssignedToOrg = false
        let swimlaneAssignments = 0
        userAssignments.get('scopes').forEach(scope => {
          // count how many role assignments are for the user for simwlanes
          if (swimlaneResourceIds.includes(scope)) {
            swimlaneAssignments++
          }

          // if it's the special org scope, set the flag
          if (scope === specialOrgScope) {
            userAssignedToOrg = true
          }
        })

        // user already has * permission  so don't need to add more assignments
        if (userAssignments.get('scopes').has(specialOrgScope)) {
          return false
        }

        // if the user isn't assigned to all the swimlanes they can be assigned
        if (
          hasSwimlanePerms &&
          swimlaneAssignments < swimlaneResourceIds.size
        ) {
          return true
        }

        // if the user isn't assigned to org they can be assigned
        if (hasNonSwimlanePerms && !userAssignedToOrg) {
          return true
        }
      })
      .forEach(user => {
        const srn = user.get('srn')
        const name = user.get('name')
        userOptions.push({ label: name, value: srn })
      })

    let filteredSwimlanes = Map()

    if (!this.state.assignmentFormValue.get('platform')) {
      if (this.state.assignmentFormValue.get('userSrn')) {
        const userId = this.state.assignmentFormValue.get('userSrn').value
        const userAssignments = scopeAssignmentIndex.get(userId)
        let userScopes = []
        if (userAssignments) {
          userScopes = userAssignments.scope || []
        }

        if (hasSwimlanePerms) {
          swimlaneResourceIds.forEach(swimlaneResourceId => {
            if (!userScopes.includes(swimlaneResourceId)) {
              const swimlane = this.props.swimlanes.find(
                swimlane => swimlaneResourceId === swimlane.get('resourceId')
              )

              if (undefined === swimlane) {
                // eslint-disable-next-line no-console
                console.warn(
                  'could not find swimlane with resourceId: ' +
                    swimlaneResourceId
                )
                return
              }
              filteredSwimlanes = filteredSwimlanes.set(
                swimlane.get('srn'),
                swimlane
              )
            }
          })
        }
      }
    }

    const userRoleAssignments = this.props.allUsers.getIn(
      [this.props.currentUser || '', 'roleAssignments', 'items'],
      List()
    )

    const userHasSpecialOrgScope = !!userRoleAssignments.find(role => {
      const permissions = role.getIn(['role', 'items', 0, 'permissions'])
      return (
        (permissions.includes('edit.roleassignments') ||
          permissions.includes('*')) &&
        role.get('scope') === specialOrgScope
      )
    })

    return [
      <Form key="assign-modalbody-form">
        <FormGroup>
          <FormLabel for="user" required>
            <DynamicFormattedMessage {...messages.user} />
          </FormLabel>
          <Select
            isClearable
            onChange={this.handleUserAssignSelect}
            value={this.state.assignmentFormValue.get('userSrn')}
            options={userOptions}
          />
        </FormGroup>
        {hasSwimlanePerms && (
          <ScopeAssignmentForm
            swimlanes={filteredSwimlanes}
            scopeInputValue={this.state.assignmentFormValue.get('scopes')}
            platformPerm={this.state.assignmentFormValue.get('platform')}
            handleScopeInputChange={this.handleScopeAssignSelect}
            handlePlatformPermClick={this.handleAssignForPlatform}
            canSelectAllSwimlanes={userHasSpecialOrgScope}
            isDisabled={!this.state.assignmentFormValue.get('userSrn')}
          />
        )}
      </Form>,
      <div
        key="assign-modalbody-error"
        className="assign-modal-error-container"
      >
        {this.state.assignmentFormError && (
          <span style={{ color: 'red' }}>
            <DynamicFormattedMessage {...messages.assignmentFormError} />
          </span>
        )}
        {this.props.roleAssignment.get('error') && (
          <span style={{ color: 'red' }}>
            <DynamicFormattedMessage {...messages.assignmentFormSubmitError} />
          </span>
        )}
      </div>,
    ]
  }

  render() {
    return (
      <div>
        <Button color="primary" onClick={this.openAssignmentModal}>
          <DynamicFormattedMessage {...messages.assignToUser} />
        </Button>
        <Modal isOpen={this.state.assignmentModalOpen}>
          <ModalHeader>
            <DynamicFormattedMessage {...messages.assignRoleToUser} />
          </ModalHeader>
          <ModalBody>{this.renderModalBody()}</ModalBody>
          <ModalFooter>
            <TextLink
              color="secondary"
              disabled={this.props.roleAssignment.get('inProgress')}
              onClick={this.closeAssignmentModal}
            >
              <DynamicFormattedMessage {...messages.cancel} />
            </TextLink>
            <Button
              color="primary"
              disabled={
                this.props.roleAssignment.get('inProgress') ||
                !this.state.assignmentFormValue.get('userSrn')
              }
              onClick={this.handleAssignRoleToUser}
            >
              <DynamicFormattedMessage {...messages.save} />
              {this.props.roleAssignment.get('inProgress') && (
                // progress inidcator for saving
                <span style={{ fontSize: '12px', paddingLeft: '8px' }}>
                  <Icon fa name="sync" spin />
                </span>
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

AssignRoleToUserModal.propTypes = {
  allUsers: ImmutablePropTypes.map,
  usersloading: PropTypes.bool,
  assignUserToRole: PropTypes.func,
  assignUserToRoleErrorClear: PropTypes.func.isRequired,
  roleAssignment: ImmutablePropTypes.contains({
    inProgress: PropTypes.bool,
    error: PropTypes.bool,
  }),
  roleDetails: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.contains({
      srn: ImmutablePropTypes.string,
      roleAssignments: ImmutablePropTypes.contains({
        items: ImmutablePropTypes.listOf(
          ImmutablePropTypes.contains({
            scope: PropTypes.string,
            users: ImmutablePropTypes.contains({
              items: ImmutablePropTypes.listOf(
                ImmutablePropTypes.contains({
                  name: PropTypes.string,
                })
              ),
            }),
          })
        ),
      }),
    }),
  }),
  swimlanes: ImmutablePropTypes.map,
  userData: ImmutablePropTypes.map,
  currentUser: PropTypes.string,
}
export default AssignRoleToUserModal
