import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import permissionChecker from 'containers/PermissionChecker'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import { ToolbarItem } from 'components/BulkActionToolbar'
import EmptyText from 'components/EmptyText'
import { FriendlyScope } from 'components/RolesDisplay'
import qs from 'query-string'
import AssignRoleToUserModal from './AssignRoleToUserModal'
import messages from './messages'
import { stripTags } from 'utils/sonraiUtils'

class RoleUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gridAPI: null,
      unsavedRoleUnassignments: [],
    }

    this.selectAllCheckbox = React.createRef()

    this.styles = {
      container: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      },
      tableContainerStyle: {
        height: '100%',
      },
      topPartContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 200px',
        marginBottom: '10px',
      },
      unassignRoleContainer: {
        background: 'white',
        zIndex: 1,
      },
      unassignRollToolbar: {
        position: 'absolute',
        bottom: '2px',
        width: '100%',
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.roleUnassignment.get('inProgress') &&
      !this.props.roleUnassignment.get('inProgress')
    ) {
      this.setState({
        unsavedRoleUnassignments: [],
      })
      if (this.state.gridAPI != null) {
        this.state.gridAPI.deselectAll()
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      gridAPI: null,
    })
  }

  onLoad = ({ api }) => {
    this.setState({ gridAPI: api })
  }

  handleUserUnassignSelect = ({ api }) => {
    const newRows = api.getSelectedRows()
    this.setState({
      unsavedRoleUnassignments: newRows,
    })
  }

  handleUserNav = srn => {
    if (srn) {
      this.props.push({
        pathname: '/App/UserManagement/User',
        search: qs.stringify({
          srn,
        }),
      })
    }
  }

  handleUnassignUsers = () => {
    this.props.unassignUsersFromRole({
      roleSrn: this.props.roleDetails.get('srn'),
      assignmentSrns: this.state.unsavedRoleUnassignments.map(
        row => row.assignment
      ),
    })
  }

  renderTopPart = () => {
    return (
      <div className="role-users-top-part" style={this.styles.topPartContainer}>
        <div>
          <DynamicFormattedMessage {...messages.usersAssignmentPreamble} />
        </div>
        <div style={{ textAlign: 'right' }}>
          <WithPermission permissionName="edit.roleassignments">
            <AssignRoleToUserModal
              allUsers={this.props.allUsers}
              assignUserToRole={this.props.assignUserToRole}
              assignUserToRoleErrorClear={this.props.assignUserToRoleErrorClear}
              roleAssignment={this.props.roleAssignment}
              roleDetails={this.props.roleDetails}
              swimlanes={this.props.swimlanes}
              userData={this.props.userData}
              currentUser={this.props.currentUser}
              usersloading={this.props.usersloading}
            />
          </WithPermission>
        </div>
      </div>
    )
  }

  checkboxRowTest = params => {
    return this.props.userHasPermission({
      permissionName: 'edit.roleassignments',
      resourceId: params.data.resourceId,
    })
  }

  renderTable = () => {
    const roleAssignments = this.props.roleDetails.getIn([
      'roleAssignments',
      'items',
    ])

    const tableData = roleAssignments
      .map(assignment => {
        const user = assignment.getIn(['user', 'items', 0])
        return {
          name: stripTags(user.get('name')),
          email: stripTags(user.get('email')),
          srn: user.get('srn'),
          scope: assignment.get('scope'),
          resourceId: user.get('resourceId'),
          assignment: assignment.get('srn'),
        }
      })
      .toJS()

    const cantUnassignAnyUsers =
      undefined ===
      tableData.find(row => {
        return this.props.userHasPermission({
          permissionName: 'edit.roleassignments',
          resourceId: row.resourceId,
        })
      })

    const bulkActions = [
      <ToolbarItem
        permissionName="edit.roleassignments"
        key="unassign"
        onClick={this.handleUnassignUsers}
      >
        <DynamicFormattedMessage {...messages.unassignRole} />
      </ToolbarItem>,
    ]

    return (
      <div
        className="role-users-table-container"
        style={this.styles.tableContainerStyle}
      >
        <div className="role-users-table">
          {false === this.props.permissions.get('loading') &&
            // don't render until permission finished loading otherwise the
            // table will redraw after and the columns will be the wrong width
            (tableData.length > 0 ? (
              <DataTable
                autosize={false}
                hasCheckBoxes={!cantUnassignAnyUsers}
                bulkActions={cantUnassignAnyUsers ? undefined : bulkActions}
                bulkActionWorking={this.props.roleUnassignment.get(
                  'inProgress'
                )}
                checkboxSelectedRows={this.state.unsavedRoleUnassignments}
                customGridProps={{ domLayout: 'autoHeight' }}
                data={tableData}
                onLoad={this.onLoad}
                selectionChanged={
                  cantUnassignAnyUsers
                    ? undefined
                    : this.handleUserUnassignSelect
                }
                onClickNodeView={this.handleUserNav}
                customColumnConfig={{
                  resourceId: { hide: true },
                  srn: { hide: true },
                  name: { flex: 1, checkboxSelection: this.checkboxRowTest },
                  email: { flex: 1 },
                  scope: {
                    flex: 1,
                    cellRendererFramework: params => {
                      if (!params.value) {
                        return ''
                      }

                      return (
                        <FriendlyScope
                          scope={params.value}
                          roles={this.props.roles}
                          swimlanes={this.props.swimlanes}
                          roleSrn={this.props.roleDetails.get('srn')}
                        />
                      )
                    },
                  },
                  assignment: { hide: true },
                }}
              />
            ) : (
              <EmptyText>(No Users assigned)</EmptyText>
            ))}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={this.styles.container}>
        {this.renderTopPart()}
        {this.renderTable()}
      </div>
    )
  }
}

RoleUsers.propTypes = {
  allUsers: ImmutablePropTypes.map,
  assignUserToRole: PropTypes.func,
  assignUserToRoleErrorClear: PropTypes.func,
  roleDetails: ImmutablePropTypes.contains({
    srn: PropTypes.string,
    roleAssignments: ImmutablePropTypes.contains({
      items: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          scope: PropTypes.string,
          users: ImmutablePropTypes.contains({
            items: ImmutablePropTypes.listOf(
              ImmutablePropTypes.contains({
                name: PropTypes.string,
              })
            ),
          }),
        })
      ),
    }),
  }),
  roleAssignment: ImmutablePropTypes.map,
  roles: ImmutablePropTypes.iterable,
  roleUnassignment: ImmutablePropTypes.contains({
    inProgress: PropTypes.bool,
    error: PropTypes.bool,
  }),
  push: PropTypes.func,
  swimlanes: ImmutablePropTypes.map,
  unassignUsersFromRole: PropTypes.func,
  usersloading: PropTypes.bool,

  // ~ from permissionChecker hoc ~
  permissions: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
  }),
  userHasPermission: PropTypes.func.isRequired,
  userData: ImmutablePropTypes.map,
  currentUser: PropTypes.string,
}

export default permissionChecker(RoleUsers)
