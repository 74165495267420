import { createAction } from 'redux-actions'
import {
  EDIT_FORM_VALUE_CHANGE,
  ASSIGN_USER_TO_ROLE,
  ASSIGN_USER_TO_ROLE_SUCCESS,
  ASSIGN_USER_TO_ROLE_ERROR,
  ASSIGN_USER_TO_ROLE_ERROR_CLEAR,
  UNASSIGN_USERS_FROM_ROLE,
  UNASSIGN_USERS_FROM_ROLE_SUCCESS,
  UNASSIGN_USERS_FROM_ROLE_ERROR,
} from './constants'

export const editFormValueChange = createAction(EDIT_FORM_VALUE_CHANGE)

export const assignUserToRole = createAction(ASSIGN_USER_TO_ROLE)
export const assignUserToRoleSuccess = createAction(ASSIGN_USER_TO_ROLE_SUCCESS)
export const assignUserToRoleError = createAction(ASSIGN_USER_TO_ROLE_ERROR)
export const assignUserToRoleErrorClear = createAction(
  ASSIGN_USER_TO_ROLE_ERROR_CLEAR
)

export const unassignUsersFromRole = createAction(UNASSIGN_USERS_FROM_ROLE)
export const unassignUsersFromRoleSuccess = createAction(
  UNASSIGN_USERS_FROM_ROLE_SUCCESS
)
export const unassignUsersFromRoleError = createAction(
  UNASSIGN_USERS_FROM_ROLE_ERROR
)
