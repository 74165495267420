import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'

import RoleDetails from './RoleDetails'
import {
  assignUserToRole,
  assignUserToRoleErrorClear,
  unassignUsersFromRole,
} from './actions'

import {
  selectSwimlanes,
  selectAllRoles,
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import {
  selectRole,
  selectRoleAssignment,
  selectRoleUnassignment,
} from './selectors'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'

export class RoleDetailsPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.styles = {
      container: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
      },
    }
  }

  renderBody() {
    return (
      <RoleDetails
        push={this.props.push}
        allUsers={this.props.allUsers}
        usersloading={this.props.usersloading}
        assignUserToRole={this.props.assignUserToRole}
        assignUserToRoleErrorClear={this.props.assignUserToRoleErrorClear}
        roleDetails={this.props.roleDetails}
        roleAssignment={this.props.roleAssignment}
        roleUnassignment={this.props.roleUnassignment}
        swimlanes={this.props.swimlanes}
        unassignUsersFromRole={this.props.unassignUsersFromRole}
        roles={this.props.roles}
        currentUser={this.props.currentUser}
      />
    )
  }

  render() {
    return (
      <div className="role-details-page" style={this.styles.container}>
        {this.renderBody()}
      </div>
    )
  }
}

RoleDetailsPage.propTypes = {
  // ~ bound action creators ~
  assignUserToRole: PropTypes.func.isRequired,
  assignUserToRoleErrorClear: PropTypes.func.isRequired,
  unassignUsersFromRole: PropTypes.func.isRequired,
  push: PropTypes.func,

  // ~ props from redux store ~
  allUsers: ImmutablePropTypes.map,
  roleDetails: ImmutablePropTypes.map,
  roleAssignment: ImmutablePropTypes.map.isRequired,
  roleUnassignment: ImmutablePropTypes.map,
  roles: ImmutablePropTypes.iterable,
  swimlanes: ImmutablePropTypes.map,
  currentUser: PropTypes.string,
  usersloading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectSonraiUserSrn,
  allUsers: selectSonraiUsers,
  usersloading: selectSonraiUsersLoading,
  roleDetails: selectRole,
  roleAssignment: selectRoleAssignment,
  roleUnassignment: selectRoleUnassignment,
  swimlanes: selectSwimlanes,
  roles: selectAllRoles,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignUserToRole,
      assignUserToRoleErrorClear,
      unassignUsersFromRole,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'roleDetails', reducer })
const withSaga = injectSaga({
  key: 'roleDetails',
  saga: sagas,
  mode: DAEMON,
})

export default compose(withConnect, withSaga, withReducer)(RoleDetailsPage)
