import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { SWIMLANE_SCOPED_PERMISSIONS } from 'appConstants'

import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'
import EmptyText from 'components/EmptyText'

import messages from './messages'

/**
 * this is the list of the permissions in a role
 */
export class RolePermissions extends Component {
  constructor(props) {
    super(props)
    this.sectionRefs = {}

    this.styles = {
      sectionContent: {
        marginBottom: '64px',
      },
      sectionContentHeaderContainer: {
        paddingTop: '14px',
        gridTemplateColumns: 'auto 200px',
        display: 'grid',
        marginBottom: '1em',
      },
      sectionContentHeaderControls: {
        textAlign: 'right',
      },
      sectionContentControlsLink: {
        marginLeft: '1em',
      },
    }
  }

  /**
   * TODO this will be different in future
   */
  getPermissionsGrouped = () => {
    return this.props.permissions.sort().reduce(
      (groupedPerms, perm) => {
        if (SWIMLANE_SCOPED_PERMISSIONS.includes(perm)) {
          groupedPerms.swimlane.push(perm)
        } else {
          groupedPerms.sonrai.push(perm)
        }
        return groupedPerms
      },
      { sonrai: [], swimlane: [] }
    )
  }

  renderSectionContent = ({ sectionKey, permissions }) => {
    let permissionsWithColumns = permissions.map(permission => ({
      action: permission,
      description: permission,
    }))

    const customColumnConfig = {
      action: {
        width: 270,
        cellRendererFramework: params => (
          <DynamicFormattedMessage
            defaultMessage={params.value}
            {...messages[`permissionAction_${params.value}`]}
          />
        ),
      },
      description: {
        flex: 1,
        cellRendererFramework: params => (
          <DynamicFormattedMessage
            defaultMessage={'-'}
            {...messages[`permissionDescription_${params.value}`]}
          />
        ),
      },
    }

    if (this.props.editMode) {
      permissionsWithColumns = permissionsWithColumns.map(permission => ({
        ...permission,
        allow: permission.action,
      }))

      // TODO if we implement the Edit/Create role, add a toggle here
      customColumnConfig.allow = {
        width: 100,
        cellRendererFramework: () => {
          return <span>Toggle</span>
        },
      }
    }

    return (
      <div
        className="section-content"
        key={`permissions-section-content-${sectionKey}`}
        style={this.styles.sectionContent}
        ref={element => {
          this.sectionRefs[sectionKey] = element
        }}
      >
        <div
          className="section-content-header"
          style={this.styles.sectionContentHeaderContainer}
        >
          <div>
            <DynamicFormattedMessage
              {...messages[`permissionTypeDescription_${sectionKey}`]}
            />
          </div>
          {this.props.editMode && (
            <div
              className="section-content-controls"
              style={this.styles.sectionContentHeaderControls}
            >
              <TextLink
                color="primary"
                style={this.styles.sectionContentControlsLink}
              >
                Select All
              </TextLink>
              <TextLink
                color="primary"
                style={this.styles.sectionContentControlsLink}
              >
                Clear All
              </TextLink>
            </div>
          )}
        </div>
        <div>
          {permissionsWithColumns.length === 0 ? (
            <EmptyText>(None Set)</EmptyText>
          ) : (
            <DataTable
              noTableActions
              autosize={false}
              data={permissionsWithColumns}
              customGridProps={{ domLayout: 'autoHeight' }}
              customColumnConfig={customColumnConfig}
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    const groupedPermissions = this.getPermissionsGrouped()

    const sectionContent = Object.keys(groupedPermissions).map(key => {
      return this.renderSectionContent({
        sectionKey: key,
        permissions: groupedPermissions[key],
      })
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.sectionContentContainer}>{sectionContent}</div>
      </div>
    )
  }
}

RolePermissions.defaultProps = {
  editMode: false,
}

RolePermissions.propTypes = {
  editMode: PropTypes.bool,
  permissions: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
}

export default RolePermissions
