import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import NodeViewTabs from 'components/NodeView/NodeViewTabs'
import SectionHeader from 'components/SectionHeader'
import TextLink from 'components/TextLink'
import RolePermissions from './RolePermissions'
import RoleUsers from './RoleUsers'
import { Helmet } from 'react-helmet'
import { getNameForHelmet } from 'utils/sonraiUtils'
import BorderedCard from 'components/BorderedCard'
import permissionChecker from 'containers/PermissionChecker'
import messages from './messages'

const TAB_PERMS = 'TAB_PERMS'
const TAB_USERS = 'TAB_USERS'
const TABS = [TAB_PERMS, TAB_USERS]

/**
 * this component is container for role information, Permission/User tabs
 */
class RoleDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: TAB_PERMS,
    }
    this.styles = {
      body: {
        height: '100%',
      },
      bodyCard: {
        height: '100%',
      },
      container: {
        height: '100%',
        padding: '1rem',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
      },
      breadcrumbs: {
        fontSize: '1rem',
        margin: '0rem 0rem 0.5rem 0rem',
      },
      title: {
        fontSize: '1.5rem',
        fontWeight: '400',
        height: '2rem',
      },
      headerContainer: {
        marginBottom: '12px',
      },
      tabContent: {
        background: 'white',
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        padding: '1em 2em',
        minHeight: '100%',
      },
    }
  }

  handleChangeTab = tabIndex => {
    this.setState({ activeTab: TABS[tabIndex] })
  }

  renderNavContext = () => {
    return (
      <div style={this.styles.breadcrumbs}>
        <Breadcrumb>
          <BreadcrumbItem>
            <TextLink
              color="primary"
              to={{
                pathname: '/App/RoleManagement',
              }}
            >
              <DynamicFormattedMessage {...messages.roles} />
            </TextLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <DynamicFormattedMessage {...messages.roleDetails} />
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
    )
  }

  renderHeader = () => {
    return (
      <BorderedCard style={this.styles.headerContainer}>
        <SectionHeader style={this.styles.title}>
          {this.props.roleDetails.get('name')}
        </SectionHeader>
        <div>{this.props.roleDetails.get('description')}</div>
      </BorderedCard>
    )
  }

  renderBody = () => {
    return (
      <NodeViewTabs
        style={{
          minHeight: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
        tabStyle={{ height: '100%' }}
      >
        <div label="Permissions">
          <div
            className="role-details-perms-tab"
            style={this.styles.tabContent}
          >
            <RolePermissions
              permissions={this.props.roleDetails
                .get('expandedPermissions', List())
                .toJS()}
            />
          </div>
        </div>
        {this.props.userHasPermission({
          permissionName: 'edit.roleassignments',
        }) ? (
          <div label="Users">
            <div
              className="role-details-user-tab"
              style={this.styles.tabContent}
            >
              <RoleUsers
                push={this.props.push}
                allUsers={this.props.allUsers}
                usersloading={this.props.usersloading}
                assignUserToRole={this.props.assignUserToRole}
                assignUserToRoleErrorClear={
                  this.props.assignUserToRoleErrorClear
                }
                roleDetails={this.props.roleDetails}
                roleAssignment={this.props.roleAssignment}
                roleUnassignment={this.props.roleUnassignment}
                swimlanes={this.props.swimlanes}
                unassignUsersFromRole={this.props.unassignUsersFromRole}
                roles={this.props.roles}
                currentUser={this.props.currentUser}
              />
            </div>
          </div>
        ) : null}
      </NodeViewTabs>
    )
  }

  render() {
    return (
      <div className="role-details" style={this.styles.container}>
        <div className="role-details-nav-context">
          {this.renderNavContext()}
        </div>
        <div className="role-details-header">{this.renderHeader()}</div>
        <div className="role-details-body" style={this.styles.body}>
          {this.renderBody()}
        </div>
        <Helmet
          title={`Sonrai - ${getNameForHelmet(this.props.roleDetails.toJS())}`}
        />
      </div>
    )
  }
}

RoleDetails.propTypes = {
  allUsers: ImmutablePropTypes.map,
  assignUserToRole: PropTypes.func.isRequired,
  assignUserToRoleErrorClear: PropTypes.func.isRequired,
  push: PropTypes.func,
  roleDetails: ImmutablePropTypes.contains({
    name: PropTypes.string,
  }),
  roleAssignment: ImmutablePropTypes.map,
  roles: ImmutablePropTypes.iterable,
  roleUnassignment: ImmutablePropTypes.map.isRequired,
  swimlanes: ImmutablePropTypes.map,
  usersloading: PropTypes.bool,
  unassignUsersFromRole: PropTypes.func.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  currentUser: PropTypes.string,
}

export default permissionChecker(RoleDetails)
