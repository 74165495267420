import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import {
  EDIT_FORM_VALUE_CHANGE,
  ASSIGN_USER_TO_ROLE,
  ASSIGN_USER_TO_ROLE_SUCCESS,
  ASSIGN_USER_TO_ROLE_ERROR,
  ASSIGN_USER_TO_ROLE_ERROR_CLEAR,
  UNASSIGN_USERS_FROM_ROLE,
  UNASSIGN_USERS_FROM_ROLE_ERROR,
  UNASSIGN_USERS_FROM_ROLE_SUCCESS,
} from './constants'

export const edittingRoleInitialState = fromJS({
  loading: false,
  errorLoading: false,
  saving: false,
  errorSaving: false,
  data: {
    name: '',
    description: '',
    permissions: [],
  },
})

export const initialState = fromJS({
  edittingRole: null,
  roleAssignment: {
    inProgress: false,
    error: false,
  },
  roleUnassignment: {
    inProgress: false,
    error: false,
  },
})

const roleDetailsReducer = handleActions(
  {
    [EDIT_FORM_VALUE_CHANGE]: (state, { payload }) =>
      state.setIn(['edittingRole', 'data'], fromJS(payload)),

    [ASSIGN_USER_TO_ROLE]: state =>
      state
        .setIn(['roleAssignment', 'inProgress'], true)
        .setIn(['roleAssignment', 'error'], false),
    [ASSIGN_USER_TO_ROLE_ERROR]: state =>
      state
        .setIn(['roleAssignment', 'inProgress'], false)
        .setIn(['roleAssignment', 'error'], true),
    [ASSIGN_USER_TO_ROLE_SUCCESS]: state =>
      state
        .setIn(['roleAssignment', 'inProgress'], false)
        .setIn(['roleAssignment', 'error'], false),
    [ASSIGN_USER_TO_ROLE_ERROR_CLEAR]: state =>
      state.setIn(['roleAssignment', 'error'], false),

    // role assignment actions
    [UNASSIGN_USERS_FROM_ROLE]: state =>
      state
        .setIn(['roleUnassignment', 'inProgress'], true)
        .setIn(['roleUnassignment', 'error'], false),
    [UNASSIGN_USERS_FROM_ROLE_ERROR]: state =>
      state
        .setIn(['roleUnassignment', 'inProgress'], false)
        .setIn(['roleUnassignment', 'error'], true),
    [UNASSIGN_USERS_FROM_ROLE_SUCCESS]: state =>
      state
        .setIn(['roleUnassignment', 'inProgress'], false)
        .setIn(['roleUnassignment', 'error'], false),
  },
  initialState
)

export default roleDetailsReducer
