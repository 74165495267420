import { all, put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'

import { getClient } from 'apolloClient'
import { UNASSIGN_USERS_FROM_ROLE, ASSIGN_USER_TO_ROLE } from './constants'
import {
  unassignUsersFromRoleSuccess,
  assignUserToRoleSuccess,
  unassignUsersFromRoleError,
  assignUserToRoleError,
} from './actions'

function* unassignUsersFromRole(action) {
  const deleters = action.payload.assignmentSrns.map(assignmentSrn => ({
    srn: assignmentSrn,
  }))
  try {
    const client = yield getClient()
    const result = client.mutate({
      variables: { deleters },
      mutation: gql`
        mutation delete($deleters: [SonraiRoleAssignmentDeleter!]!) {
          DeleteSonraiRoleAssignments(input: $deleters) {
            count
          }
        }
      `,
    })
    if (result.data == false) {
      // TODO check for null here once this is implemented
      throw 'delete mutation returned false'
    }
    yield put(unassignUsersFromRoleSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error unassigning users from roles', e)
    yield put(unassignUsersFromRoleError())
  }
}

function* assignUserToRole(action) {
  try {
    const { scopes, userSrn, roleSrn } = action.payload
    const assignments = scopes.map(scope => ({
      scope,
      userSrn,
      roleSrn,
    }))
    const client = yield getClient()
    const result = yield client.mutate({
      variables: { assignments },
      mutation: gql`
        mutation ceateRoleAssignment(
          $assignments: [SonraiRoleAssignmentCreator!]!
        ) {
          CreateSonraiRoleAssignments(input: $assignments) {
            count
          }
        }
      `,
    })
    const data = _.get(
      result,
      ['data', 'CreateSonraiRoleAssignments', 'count'],
      null
    )
    if (!data) {
      throw 'did not receive data result'
    }
    yield put(assignUserToRoleSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error assigning the user to the role ', e)
    yield put(assignUserToRoleError())
  }
}

function* roleDetailsSaga() {
  yield all([
    takeLatest(ASSIGN_USER_TO_ROLE, assignUserToRole),
    takeLatest(UNASSIGN_USERS_FROM_ROLE, unassignUsersFromRole),
  ])
}

export default roleDetailsSaga
